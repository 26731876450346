import { BaseApi } from 'Core/api/BaseApi'

export class Api extends BaseApi {
  getUsers = async fields => {
    const defaultResponse = {users: [], totalCount: 0, error: null}
    try {
      const {body: {error, users, usersCount}} = await Api.post('/user/list', fields)
      if (!error && Array.isArray(users)) {
        return {users, totalCount: usersCount}
      } else {
        defaultResponse.error = error
        return defaultResponse
      }
    } catch (e) {
      defaultResponse.error = e
      return defaultResponse
    }
  }
}
