import PropTypes from 'prop-types'
import React from 'react'
import { imagesCDN } from 'Core/constants'
import withTranslation from 'next-translate/withTranslation'
import Link from 'next/link'
import { isUserOnline } from 'SRC/utils'
import { getAdsCount, getMainAddress, getLinkAs, getLinkHref } from '../../../functions'

class Item extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      screenWidth: null
    }
  }

  componentDidMount () {
    this.setState({ screenWidth: window.innerWidth })

    document.addEventListener('scroll', this.updateScreenWidth.bind(this))
  }

  updateScreenWidth () {
    if (this.state.screenWidth !== window.innerWidth) {
      this.setState({ screenWidth: window.innerWidth })
    }
  }

  getName = (name, isPremium) => {
    if (isPremium) {
      if (this.state.screenWidth) {
        if (this.state.screenWidth > 1199) {
          return <a>{name.length < 30 ? name : `${name.substr(0, 29)}...`}</a>
        }
      }
      return <a>{name.length < 21 ? name : `${name.substr(0, 20)}...`}</a>
    } else {
      return <a>{name.length < 25 ? name : `${name.substr(0, 24)}...`}</a>
    }
  }

  render () {
    const {
      name,
      logo: {fd},
      active,
      userAddresses,
      username,
      seo,
      userTypeSeo,
      adsCategories,
      category,
      className,
      premium,
      i18n: { t }
    } = this.props

    return <div className={className}>
      <div className='user-header'>
        <Link
          href={getLinkHref({ username, seo, userTypeSeo, category })}
          as={getLinkAs({ username, seo, userTypeSeo, category })}
        >
          {this.getName(name, premium)}
        </Link>
      </div>
      <div className='user-content'>
        <div className='user-img'>
          <Link
            href={getLinkHref({ username, seo, userTypeSeo, category })}
            as={getLinkAs({ username, seo, userTypeSeo, category })}
          >
            <a>
              {fd ? (
                // <Image src={`${imagesCDN}${fd}`} alt={username} layout='fill' objectFit='contain' />
                <img src={`${imagesCDN}${fd}`} alt={username} />
              ) : (
                // <Image src='/icons/no-image-placeholder.svg' alt='no-image-placeholder' layout='fill' />
                <img src='/icons/no-image-placeholder.svg' alt='no-image-placeholder' />
              )}
            </a>
          </Link>
        </div>
        <div className='user-info'>
          <div className='user-status'>
            <span>{username}</span>
            <span className='user-online-status-text'>
              {isUserOnline(active) ? `Online` : `Offline`} &nbsp;
              <span className={`user-online-status ${isUserOnline(active) ? `online` : `offline`}`} />
            </span>
          </div>
          <div className='user-broj-artikala'>
            <Link
              href={getLinkHref({ username, seo, userTypeSeo, category })}
              as={getLinkAs({ username, seo, userTypeSeo, category })}
            >
              <a>{getAdsCount(adsCategories)} {t('common:oglasa')}</a>
            </Link>
          </div>
          <div className='user-lokacija'>
            <i className='ico-lokacija ico' />
            <p>
              {getMainAddress(userAddresses)} {userAddresses.length > 1 ? `(+${userAddresses.length - 1})` : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  }
}

Item.propTypes = {
  userAddresses: PropTypes.arrayOf(PropTypes.shape({
    isMain: PropTypes.bool,
    city: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  })).isRequired,
  logo: PropTypes.shape({
    fd: PropTypes.string
  }),
  active: PropTypes.string,
  premium: PropTypes.bool,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  seo: PropTypes.string,
  userTypeSeo: PropTypes.string.isRequired,
  adsCategories: PropTypes.shape({
    count: PropTypes.number
  }),
  category: PropTypes.string.isRequired,
  className: PropTypes.string
}

Item.defaultProps = {
  userAddresses: [],
  premium: false,
  logo: {},
  category: ''
}

export default withTranslation(Item)
