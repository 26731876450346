import React from 'react'
import PropTypes from 'prop-types'
import { fetchCategories } from 'SRC/modules/categories/actions'
import { getFlattenCategories } from 'SRC/modules/categories/selectors'
import { getCountries } from 'SRC/modules/geoObjects/countries/selectors'
import { connect } from 'react-redux'
import withTranslation from 'next-translate/withTranslation'
import { CitiesApi } from 'SRC/modules/geoObjects/cities/api'
import { LocationsApi } from 'SRC/modules/geoObjects/locations/api'
import { reduxForm, change, Form } from 'redux-form'
import { compose } from 'recompose'
import { Select, TextInput } from 'SRC/ui/FormElements'
import Router from 'next/router'
import { setQuery, setLastChangedField, setLoading } from '../../../actions'
import { getQuery, getLoading, getUserType, getLastChangedField } from '../../../selectors'
import { getCitiesByCountryWithinRegions } from 'SRC/modules/geoObjects/cities/selectors'
import { getLocations } from 'SRC/modules/geoObjects/locations/selectors'
import { getServices } from 'SRC/modules/ads/services/selectors'
import { getServiceTypes } from 'SRC/modules/ads/serviceTypes/selectors'
import { ServiceTypesApi } from 'SRC/modules/ads/serviceTypes/api'
import config from 'SRC/config/config.json'

export class FilterForm extends React.PureComponent {
  static defaultProps = {
    categories: [],
    countries: [],
    cities: [],
    locations: [],
    isLoading: false
  }

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired
    })).isRequired,
    countries: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
    cities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      region: PropTypes.number.isRequired
    })).isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
    initialValues: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    baseAs: PropTypes.string.isRequired,
    setQuery: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    userType: PropTypes.shape({
      seo: PropTypes.string.isRequired
    }),
    services: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    setLastChangedField: PropTypes.func.isRequired,
    lastChangedField: PropTypes.string,
    setLoading: PropTypes.func.isRequired
  }

  static formName = 'users-filter'

  constructor (props) {
    super(props)

    const { initialValues, cities } = props

    let initialCities = []

    if (initialValues?.country && cities && Array.isArray(cities[initialValues.country])) {
      initialCities = this.prepareCities(cities[initialValues.country])
    }

    this.state = {
      cities: initialCities,
      isCitiesLoading: false,
      locations: this.prepareLocations(this.props.locations),
      isLocationsLoading: false,
      serviceTypes: this.prepareServiceTypes(this.props.serviceTypes),
      isServiceTypesLoading: false
    }

    if (Router.router) {
      Router.router.events.on('routeChangeStart', this.saveScrollY)
      Router.router.events.on('routeChangeComplete', this.scrollToSavedScroll)
    }

    this.scrollY = null
  }

  componentDidMount() {
    const { fetchCategories } = this.props

    fetchCategories({})
  }

  saveScrollY = () => {
    this.scrollY = window.scrollY
  }

  scrollToSavedScroll = () => {
    window.scrollTo(window.scrollX, this.scrollY)
  }

  getCategoryField = () => {
    const { i18n: { t } } = this.props

    return {
      id: 'category',
      name: 'category',
      options: this.prepareCategories(),
      labelDefault: t('common:Izaberite kategoriju'),
      className: 'users-list__select',
      needColon: false,
      disabled: this.props.isLoading
    }
  }

  getCountryField = () => {
    const { i18n: { t } } = this.props

    return {
      id: 'country',
      name: 'country',
      options: this.prepareCountries(),
      onChange: this.onCountryChange,
      labelDefault: t('common:Izaberite državu'),
      className: 'users-list__select',
      needColon: false,
      disabled: this.props.isLoading
    }
  }

  getCityField = () => {
    const { i18n: { t } } = this.props

    return {
      id: 'city',
      name: 'city',
      options: this.state.cities,
      onChange: this.onCityChange,
      disabled: this.state.isCitiesLoading || this.props.isLoading,
      labelDefault: t('common:Izaberite grad'),
      className: 'users-list__select',
      needColon: false
    }
  }

  getLocationField = () => {
    const { i18n: { t } } = this.props

    return {
      id: 'locations',
      name: 'location',
      options: this.state.locations,
      disabled: this.state.isLocationsLoading || this.props.isLoading,
      labelDefault: t('common:Izaberite lokaciju'),
      className: 'users-list__select users-list__select_last',
      needColon: false
    }
  }

  getSearchField = () => ({
    id: 'fullText',
    name: 'fullText',
    placeholder: this.props.placeholder,
    wrapperClass: `users-list__search ${this.isServiceProvider() ? 'users-list__search_short' : ''}`,
    needColon: false,
    isBlocked: this.props.isLoading
  })

  prepareCategories = () => this.props.categories.filter(category => !category.parent).map(category => ({
    id: category.id,
    label: category.title,
    isGrouped: true,
    value: category.id,
    options: this.props.categories.filter(item => item.parent === category.id).map(item => ({
      value: item.id,
      isDisabled: false,
      label: item.title
    }))
  }))

  prepareCountries = () => this.props.countries.filter(country => !country.parent).map(country => {
    const isGrouped = Boolean(this.props.countries.filter(item => item.parent === country.id).length)
    return {
      id: country.id,
      label: country.label,
      isGrouped,
      isDisabled: false,
      value: country.id,
      options: isGrouped ? this.props.countries.filter(item => item.parent === country.id).map(item => ({
        value: item.id,
        isDisabled: false,
        label: item.label
      })) : null
    }
  })

  onCountryChange = async e => {
    const country = Number(e.target.value)
    await this.props.change(this.getCityField().name, null)
    await this.props.change(this.getLocationField().name, null)
    await this.loadCitiesToState(country)
  }

  loadCitiesToState = async country => {
    this.setState({
      isCitiesLoading: true,
      cities: [],
      locations: [],
      isLocationsLoading: true
    })
    const citiesApi = new CitiesApi()

    const citiesWithinRegions = await citiesApi.getCitiesByCountryWithinRegions(country)
    const preparedCities = this.prepareCities(citiesWithinRegions)

    this.setState({ cities: preparedCities, isCitiesLoading: false })
  }

  prepareCities = (citiesWithinRegions = []) => citiesWithinRegions.map(region => ({
    id: region.id,
    label: region.label,
    value: `region-${region.id}`,
    isGrouped: true,
    options: region.cities.map(city => ({
      id: city.id,
      label: city.label,
      value: city.value,
      region: region.id
    }))
  }))

  onCityChange = async e => {
    const city = Number(e.target.value)
    await this.props.change(this.getLocationField().name, null)
    await this.loadLocationsToState(city)
  }

  loadLocationsToState = async city => {
    await this.setState({...this.state, isLocationsLoading: true})
    const api = new LocationsApi()
    const locations = await api.getLocationsByCity(Number(city))
    this.setState({
      ...this.state,
      locations: this.prepareLocations(locations),
      isLocationsLoading: !locations.length
    })
  }

  prepareLocations = (locations = []) => locations.map(({id, label}) => ({id, label, value: id}))

  onSubmit = values => {
    if (!this.props.isLoading) {
      this.props.setLoading(true)
      const deniedKeys = ['userId', 'token']
      const searchFieldName = this.getSearchField().name
      const isNeedToResetFilterValues = this.props.lastChangedField === searchFieldName
      const notEmptyValues = {}

      let hrefQuery = {...Router.query}

      for (let key in values) {
        if (values.hasOwnProperty(key) && !deniedKeys.includes(key)) {
          if (values[key]) {
            if (isNeedToResetFilterValues) {
              if (key === searchFieldName) notEmptyValues[key] = values[key]
              else delete hrefQuery[key]
            } else {
              if (key !== searchFieldName) notEmptyValues[key] = values[key]
              else delete hrefQuery[key]
            }
          } else {
            if (hrefQuery.hasOwnProperty(key)) delete hrefQuery[key]
          }
        }
      }

      hrefQuery = {...hrefQuery, ...notEmptyValues}

      const {type, ...asValues} = notEmptyValues
      const asQuery = isNeedToResetFilterValues ? {[searchFieldName]: values[searchFieldName]} : asValues
      const href = {pathname: Router.route, query: hrefQuery}
      const as = {pathname: this.props.baseAs, query: asQuery}

      // Router.push(href, as, {shallow: true}).then(() => {
      //   this.props.setQuery(asQuery)
      // })
      Router.push(href, as)
    }
  }

  isServiceProvider = () => this.props.userType && this.props.userType.seo === config.userTypes.serviceProviderSeo

  getServicesField = () => {
    const { i18n: { t } } = this.props

    return {
      id: 'service',
      labelDefault: t('common:Izaberite djelatnost'),
      name: 'service',
      onChange: this.onServiceChange,
      options: this.prepareServices(this.props.services),
      needColon: false,
      className: 'users-list__select'
    }
  }

  getServiceTypesField = () => {
    const { i18n: { t } } = this.props

    return {
      id: 'serviceType',
      labelDefault: t('common:Izaberite tip usluge'),
      name: 'serviceType',
      disabled: this.state.isServiceTypesLoading,
      options: this.state.serviceTypes,
      needColon: false,
      className: 'users-list__select'
    }
  }

  prepareServices = services => services.map(({id, name}) => ({id, label: name, value: id}))

  prepareServiceTypes = (serviceTypes = []) => serviceTypes.map(({id, name}) => ({id, label: name, value: id}))

  onServiceChange = async e => {
    const service = Number(e.target.value)
    await this.props.change(this.getServiceTypesField().name, null)
    await this.loadServiceTypesToState(service)
  }

  loadServiceTypesToState = async service => {
    if (service) {
      await this.setState({...this.state, isServiceTypesLoading: true})
      const api = new ServiceTypesApi()
      const serviceTypes = await api.fetchServiceTypesByService(service)
      await this.setState({
        ...this.state,
        serviceTypes: this.prepareServiceTypes(serviceTypes),
        isServiceTypesLoading: !serviceTypes.length
      })
    }
  }

  render () {
    const isServiceProvider = this.isServiceProvider()
    return (
      <Form className='pretraga-prodavnica-form' onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div className='users-list__filter-row'>
          {!isServiceProvider ? <Select {...this.getCategoryField()} /> : null}
          {isServiceProvider ? <Select {...this.getServicesField()} /> : null}
          {isServiceProvider ? <Select {...this.getServiceTypesField()} /> : null}
          <TextInput {...this.getSearchField()} />
          <button type='submit'><i className='ico-pretraga-text-search-lupa ico' /></button>
        </div>
        <div className='users__filter-row'>
          <Select {...this.getCountryField()} />
          <Select {...this.getCityField()} />
          <Select {...this.getLocationField()} />
        </div>
      </Form>
    )
  }
}

const mapStateToProps = state => ({
  categories: getFlattenCategories(state),
  countries: getCountries(state),
  cities: getCitiesByCountryWithinRegions(state),
  locations: getLocations(state),
  initialValues: getQuery(state),
  isLoading: getLoading(state),
  userType: getUserType(state),
  services: getServices(state),
  serviceTypes: getServiceTypes(state),
  lastChangedField: getLastChangedField(state)
})

export default compose(
  connect(mapStateToProps, {change, setQuery, setLastChangedField, setLoading, fetchCategories}),
  reduxForm({
    form: FilterForm.formName,
    enableReinitialize: true,
    onChange: (values, dispatch, props, previousValues) => {
      for (let key in values) {
        if (values.hasOwnProperty(key)) {
          if (values[key] !== previousValues[key]) {
            dispatch(setLastChangedField(key))
          }
        }
      }
      if (values.fullText === previousValues.fullText && !props.isLoading) {
        setTimeout(() => {
          props.submit()
        }, 1)
      }
    }
  }),
  withTranslation
)(FilterForm)
