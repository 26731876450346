import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import StpdBannerComponent from 'stpd-ad-component'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'

import config from 'SRC/config/config.json'

import { UserList, SellersFilter } from '../../components'

const SellersPage = ({ isMobileDevice }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const slot = config.banners.users.mobileTop
  const slotTablet = config.banners.users.mobileTopTablet

  const isTablet = windowWidth > 727

  return (
    <React.Fragment>
      <SellersFilter key='filter' />

      {isMobileDevice ? (
        <div key='banner' style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: !isTablet ? 320 : 728, margin: 'auto' }}>
            <StpdBannerComponent
              refreshOnUrlChange={true}
              adUnitPath={`/${config.banners.dfpNetworkId}/${!isTablet ? slot.adUnit : slotTablet.adUnit}`}
              size={!isTablet ? slot.sizes : slotTablet.sizes}
              divId={!isTablet ? slot.slotId : slotTablet.slotId}
            />
          </div>
        </div>
      ) : null}

      <UserList key='list' />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({ isMobileDevice: getIsMobileDevice(state) })

export default connect(mapStateToProps)(SellersPage)
