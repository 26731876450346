import PropTypes from 'prop-types'
import React from 'react'
import { FilterForm } from '../FilterForm'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { getPagination, getUserType } from '../../../selectors'
import config from 'SRC/config/config.json'

const SellersFilter = ({ userType, usersCount }) => {
  const { t } = useTranslation('common')

  const getTextsByUserType = () => {
    if (!userType) return null

    // return {
    //   searchText: config.userTypes.usersFilter[userType.seo].searchText,
    //   countText: config.userTypes.usersFilter[userType.seo].countText,
    //   description: config.userTypes.usersFilter[userType.seo].description,
    //   placeholder: config.userTypes.usersFilter[userType.seo].placeholder
    // }
    return {
      searchText: t(`${userType.seo}SearchText`),
      countText: t(`${userType.seo}CountText`),
      description: t(`${userType.seo}Description`),
      placeholder: t(`${userType.seo}Placeholder`)
    }
  }

  const texts = getTextsByUserType()

  if (!texts) return null

  return (
    <div className='pretraga-prodavnica'>
      <div className='pretraga-prodavnica-header clearfix'>
        <p className='heading'>{texts.searchText}</p>
        <p className='broj-prodavnica'>{texts.countText} <span>{usersCount}</span></p>
      </div>
      <div className='pretraga-prodavnica-content'>
        {texts.description.split(`<br />`).map((line, i) => <p key={i}>{line}</p>)}
        <FilterForm baseAs={`/${userType.seoPlu}`} placeholder={texts.placeholder} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const pagination = getPagination(state)
  return {
    usersCount: pagination && pagination.totalCount ? pagination.totalCount : 0,
    userType: getUserType(state)
  }
}

SellersFilter.propTypes = {
  usersCount: PropTypes.number.isRequired,
  userType: PropTypes.shape({
    seo: PropTypes.string.isRequired,
    seoPlu: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    namePlu: PropTypes.string.isRequired
  })
}

SellersFilter.defaultProps = {
  usersCount: 0
}

export default connect(mapStateToProps)(SellersFilter)
