import React, { useState, useEffect } from 'react'
import StpdBannerComponent from 'stpd-ad-component'

import config from 'SRC/config/config.json'

export const Banner = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const slot = config.banners.users.list
  const slotTablet = config.banners.users.listTablet

  return (
    <div className='user-item user-item-banner'>
      <div style={{ width: windowWidth <= 727 ? 320 : 728, margin: 'auto' }}>
        <StpdBannerComponent
          refreshOnUrlChange={true}
          adUnitPath={`/${config.banners.dfpNetworkId}/${windowWidth <= 727 ? slot.adUnit : slotTablet.adUnit}`}
          size={windowWidth <= 727 ? slot.sizes : slotTablet.sizes}
          divId={windowWidth <= 727 ? slot.slotId : slotTablet.slotId}
        />
      </div>
    </div>
  )
}
