import PropTypes from 'prop-types'
import React from 'react'
import { imagesCDN } from 'Core/constants'
import Link from 'next/link'
import { isUserOnline } from 'SRC/utils'
import useTranslation from 'next-translate/useTranslation'
import config from 'SRC/config/config.json'

import { getAdsCount, getImgStyle, getMainAddress, getLinkAs, getLinkHref } from '../../../functions'

export const Item = ({
  premium,
  name,
  logo: { fd },
  active,
  userAddresses,
  username,
  seo,
  userTypeSeo,
  adsCategories,
  category,
  brands
}) => {
  const { t } = useTranslation('common')

  return (
    <div className={`zastupnik-item ${premium ? `pro-user` : ``}`}>
      <div className='user-header'>
        <Link
          href={getLinkHref({ username, seo, userTypeSeo, category })}
          as={getLinkAs({ username, seo, userTypeSeo, category })}
        >
          <a>{name}</a>
        </Link>
      </div>
      <div className='user-content'>
        <div className='user-img'>
          <Link
            href={getLinkHref({ username, seo, userTypeSeo, category })}
            as={getLinkAs({ username, seo, userTypeSeo, category })}
          >
            <a>
              <img src={`${imagesCDN}${fd}`} alt={username} />
            </a>
          </Link>
        </div>

        <div className='user-info'>
          <div className='user-username'>
            <i className='ico-top-meni-prijavi-se ico' />
            &nbsp; <span>{username}</span>
          </div>

          <div className='user-status'>
            <span className='user-online-status-text'>
              <span className={`user-online-status ${isUserOnline(active) ? `online` : `offline`}`} />
              &nbsp; {isUserOnline(active) ? `Online` : `Offline`}
            </span>
          </div>

          <div className='user-broj-artikala'>
            <Link
              href={getLinkHref({ username, seo, userTypeSeo, category })}
              as={getLinkAs({ username, seo, userTypeSeo, category })}
            >
              <a>{getAdsCount(adsCategories)} {t('oglasa')}</a>
            </Link>
          </div>
        </div>

        <div className='zastupnik-brands'>
          {Array.isArray(brands) ? brands.map(brand => brand && brand.logo
            ? <img width={40} height={40} src={`${config.mediaCDN}/${brand.logo}`} alt={brand.name} />
            : null
          ) : null}
        </div>
      </div>

      <div className='user-lokacija'>
        <i className='ico-lokacija ico' />
        <p>
          {getMainAddress(userAddresses)} {userAddresses.length > 1 ? `(+${userAddresses.length - 1})` : null}
        </p>
      </div>
    </div>
  )
}

Item.propTypes = {
  userAddresses: PropTypes.arrayOf(PropTypes.shape({
    isMain: PropTypes.bool,
    city: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  })).isRequired,
  logo: PropTypes.shape({
    fd: PropTypes.string
  }),
  active: PropTypes.string,
  premium: PropTypes.bool,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  seo: PropTypes.string,
  userTypeSeo: PropTypes.string.isRequired,
  brands: PropTypes.array.isRequired,
  adsCategories: PropTypes.shape({
    count: PropTypes.number
  }),
  category: PropTypes.string.isRequired
}

Item.defaultProps = {
  userAddresses: [],
  premium: false,
  logo: {},
  category: ''
}
