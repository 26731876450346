import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import withTranslation from 'next-translate/withTranslation'
import { Page, PageHeader, Page404 } from '../src/ui'
import { SellersPage } from '../src/modules/users/list/pages'
import { fetchCountries } from '../src/modules/geoObjects/countries/actions'
import { fetchCitiesByCountryWithinRegions, setCitiesByCountryWithinRegions } from '../src/modules/geoObjects/cities/actions'
import { fetchLocations, setLocations } from '../src/modules/geoObjects/locations/actions'
import { fetchUsers, setUserType } from '../src/modules/users/list/actions'
import { getUserType } from '../src/modules/users/list/selectors'
import { capitalize, deepCopy } from '../src/utils/Utils'
import { globalOptions } from '../src/core/constants'
import { CookiesHelper } from '../src/core/cookiesHelper/CookiesHelper'
import { fetchServices } from '../src/modules/ads/services/actions'
import { fetchServiceTypes, setServiceTypes } from '../src/modules/ads/serviceTypes/actions'
import initialState from '../src/modules/redux/initialState'
import config from '../src/config/config.json'

class UsersPage extends React.Component {
  static async getInitialProps({ store, query, isServer, req }) {
    console.log('users page query', query)
    store.dispatch(setUserType(query.userType))
    let isPageExists = false
    const state = store.getState()
    const selectedUserType = getUserType(state)

    if (selectedUserType) isPageExists = true

    if (isPageExists) {
      let queryParams

      if (query) queryParams = { ...query }

      if (queryParams) {
        const type = selectedUserType.id
        const limit = globalOptions.usersCountPerPage
        const page = 1
        const { userType, ...rest } = queryParams
        const requestParams = queryParams ? { page, ...rest, limit, type } : { type, limit, page }

        requestParams.locale = query.locale?.toUpperCase() || 'ME'

        await store.dispatch(fetchUsers(requestParams, []))
      }

      await store.dispatch(fetchCountries())

      if (queryParams.country) {
        const country = Number(queryParams.country)
        await store.dispatch(fetchCitiesByCountryWithinRegions(country))
      } else {
        store.dispatch(setCitiesByCountryWithinRegions({}))
      }

      if (selectedUserType.seo === config.userTypes.serviceProviderSeo) {
        await store.dispatch(fetchServices(query?.locale?.toUpperCase() || 'ME'))
        const service = query.service

        if (service) await store.dispatch(fetchServiceTypes({ service }))
        else store.dispatch(setServiceTypes([]))

      }
      if (queryParams.city) {
        await store.dispatch((fetchLocations(Number(queryParams.city))))
      } else {
        store.dispatch(setLocations([]))
      }
    }

    return {
      title: isPageExists
        ? `${capitalize(selectedUserType && selectedUserType.namePlu)} | ${config.pages.defaultTitle}`
        : config.pages.notFoundTitle,
      userType: selectedUserType,
      isPageExists,
      state: UsersPage.getPageState(store, isPageExists)
    }
  }

  static getPageState(store, isPageExists = false) {
    const state = deepCopy(initialState)
    if (store) {
      const currentState = store.getState()

      state.user.auth.isAuthorized = currentState.user.auth.isAuthorized
      state.user.profile.error = currentState.user.profile.error
      state.user.profile.info = currentState.user.profile.info
      state.user.profile.favouriteAds = currentState.user.profile.favouriteAds
      state.user.profile.savedFilters = currentState.user.profile.savedFilters
      state.user.profile.favouriteProfiles = currentState.user.profile.favouriteProfiles
      state.user.profile.priceTypes = currentState.user.profile.priceTypes
      state.user.list = currentState.user.list
      state.user.listSidebar = currentState.user.listSidebar

      state.common.transitions = currentState.common.transitions
      state.common.router = currentState.common.router
      state.common.is404 = !isPageExists
      state.common.isMobileDevice = currentState.common.isMobileDevice
      state.common.userTypes = currentState.common.userTypes
      state.common.pageMetaTags = currentState.common.pageMetaTags
      state.common.pageMetaTagsSingle = currentState.common.pageMetaTagsSingle

      state.groups.elements = currentState.groups.elements

      state.categories.elements = currentState.categories.elements
      state.categories.bySeo = currentState.categories.bySeo

      state.ads.detail.reportTypes = currentState.ads.detail.reportTypes
      state.ads.detail.registrationFormulas = currentState.ads.detail.registrationFormulas

      if (isPageExists) {
        state.geoObjects.countries = currentState.geoObjects.countries
        state.geoObjects.regions = currentState.geoObjects.regions
        state.geoObjects.cities = currentState.geoObjects.cities
        state.geoObjects.locations = currentState.geoObjects.locations

        state.ads.services = currentState.ads.services
        state.ads.serviceTypes = currentState.ads.serviceTypes
      }

      state.form = currentState.form
    }
    return state
  }

  render() {
    const { userType, isPageExists, i18n: { t } } = this.props

    return isPageExists ? (
      <Page>
        <PageHeader>{capitalize(userType ? t(`common:${userType.namePlu}`) : '')}</PageHeader>
        {config.userTypes.businessUsersSeos.includes(userType.seo) ||
          userType.seo === config.userTypes.serviceProviderSeo ? <SellersPage /> : null}
      </Page>
    ) : <Page404 />
  }
}

UsersPage.propTypes = {
  userType: PropTypes.shape({
    seo: PropTypes.string.isRequired,
    namePlu: PropTypes.string.isRequired
  }).isRequired,
  isPageExists: PropTypes.bool.isRequired
}

UsersPage.defaultProps = {
  isPageExists: false
}

export default withTranslation(UsersPage)
