import PropTypes from 'prop-types'
import React from 'react'
import { Preloader, AdditionalPreloader } from 'SRC/ui/Preloader'
import { connect } from 'react-redux'
import { globalOptions } from 'Core/constants'
import { getAppLoading } from 'SRC/modules/common/selectors'
import { getFlattenCategories } from 'SRC/modules/categories/selectors'
import { Item } from '../Item'
import { Banner } from '../Banner'
import { ItemWithBrands } from '../ItemWithBrands'
import { isObjectEquals } from 'SRC/utils/Utils'
import { fetchUsers } from '../../../actions'
import { getLoading, getElements, getQuery, getUserType, getPagination, getAdditionalLoading } from '../../../selectors'
import config from 'SRC/config/config.json'

export class List extends React.Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (!this.props.isLoading && !this.props.isAdditionalLoading && this.props.users.length < this.props.pagination.totalCount) {
      const blockBorder = this.ref.current.scrollTop + this.ref.current.clientHeight
      if (this.ref.current.scrollHeight >= blockBorder) {
        const newPage = this.props.pagination.page + 1
        this.props.fetchUsers(this.getRequestParams(newPage), this.props.users, true)
      }
    }
  }

  getRequestParams = (page = 1) => {
    const type = this.props.userType && this.props.userType.id
    const limit = globalOptions.usersCountPerPage
    return { ...this.props.query, page, limit, type }
  }

  hasBrandAddon = user => {
    return user.activeAddons.find(addon =>
      addon.action.label === config.userTypes.usersWithBrandsAddonActionLabel)
  }

  getItemClassName = (user, index) => {
    const classNames = ['user-item']
    const usersCount = this.props.users.length
    if (user.premium) classNames.push('pro-user')
    if (index === usersCount - 1 && usersCount % 3 > 0) {
      classNames.push('even')
    }
    return classNames.join(' ')
  }

  render() {
    const { isLoading, users, isAdditionalLoading, userType, isAppLoading, categorySeo } = this.props

    return (
      <div className='prodavnice-content' ref={this.ref}>
        {users.map((user, index) => {
          if (user.isBanner) return <Banner key={user.key} />

          if (this.hasBrandAddon(user) && userType && userType.seo === config.userTypes.zastupnikSeo) {
            return (
              <ItemWithBrands
                key={user.username}
                {...user}
                userTypeSeo={userType && userType.seo}
                category={categorySeo}
              />
            )
          }

          return (
            <Item
              key={user.username}
              {...user}
              userTypeSeo={userType && userType.seo}
              category={categorySeo}
              className={this.getItemClassName(user, index)}
            />
          )
        })}
        {!isAppLoading && isLoading ? <Preloader /> : null}
        {!isAppLoading && isAdditionalLoading ? <AdditionalPreloader /> : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const query = getQuery(state)
  let categorySeo = ''
  const categories = getFlattenCategories(state)
  if (query && query.category) {
    const categoryInFilter = categories.filter(item => item.id === Number(query.category))
    if (categoryInFilter.length) {
      categorySeo = categoryInFilter[0].seo
    }
  }
  return {
    isLoading: getLoading(state),
    users: getElements(state),
    query,
    userType: getUserType(state),
    pagination: getPagination(state),
    isAdditionalLoading: getAdditionalLoading(state),
    isAppLoading: getAppLoading(state),
    categorySeo
  }
}

List.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAdditionalLoading: PropTypes.bool.isRequired,
  isAppLoading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired
  })).isRequired,
  query: PropTypes.shape(),
  fetchUsers: PropTypes.func.isRequired,
  userType: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seo: PropTypes.string.isRequired
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  categorySeo: PropTypes.string.isRequired
}

List.defaultProps = {
  isLoading: false,
  isAppLoading: false,
  isAdditionalLoading: false,
  users: [],
  categorySeo: ''
}

export default connect(mapStateToProps, { fetchUsers })(List)
