import { imagesCDN } from 'Core/constants'

export const getMainAddress = userAddresses => {
  if (Array.isArray(userAddresses)) {
    const mainAddress = userAddresses.filter(item => item.isMain)
    if (mainAddress.length) {
      return mainAddress[0].city && mainAddress[0].city.name
    } else {
      return userAddresses[0].city && userAddresses[0].city.name
    }
  }
  return null
}

export const getAdsCount = (adsCategories) => {
  let count = 0
  if (adsCategories && adsCategories.count) {
    count += adsCategories.count
  }
  return count
}

export const getImgStyle = (fd) => {
  if (!fd) return null

  return {
    background: `url("${imagesCDN}${fd}") center center/contain no-repeat`,
    width: '100%',
    height: '100%'
  }
}

export const getLinkHref = ({ username, seo, userTypeSeo, category }) => {
  return category
    ? `/user?username=${seo || username}&userType=${userTypeSeo}&category=${category}`
    : `/user?username=${seo || username}&userType=${userTypeSeo}`
}

export const getLinkAs = ({ username, seo, userTypeSeo, category }) => {
  return category ? `/${userTypeSeo}/${seo || username}/${category}` : `/${userTypeSeo}/${seo || username}`
}
