import { setElements } from '../setElements'
import { setLoading } from '../setLoading'
import { setPagination } from '../setPagination'
import { setQuery } from '../setQuery'
import { setAdditionalLoading } from '../setAdditionalLoading'
import { UsersListApi } from '../../api'

const getBanerIndex = usersLength => {
  if (!usersLength) return 0
  if (usersLength <= 2) return usersLength

  let index = 3

  while (index % 2 !== 0) index = Math.floor(Math.random() * (usersLength - 1)) + 1

  return index
}

export const fetchUsers = (fields, existingUsers = [], isAdditionalLoading = false) => async (dispatch, getState) => {
  try {
    isAdditionalLoading ? dispatch(setAdditionalLoading(true)) : dispatch(setLoading(true))
    const { limit, page, userType, ...rest } = fields

    dispatch(setQuery(rest))

    const api = new UsersListApi()
    const { users, totalCount, error } = await api.getUsers(fields)
    const newUsers = [...users]

    const isMobileDevice = getState().common.isMobileDevice

    // Insert banner at random position
    if (newUsers.length && isMobileDevice) {
      const randomIndex = getBanerIndex(newUsers.length)
      newUsers.splice(randomIndex, 0, { key: 'banner', isBanner: true })
    }

    if (!error && Array.isArray(newUsers)) {
      dispatch(setElements(existingUsers.concat(newUsers)))
      dispatch(setPagination({
        countPerPage: limit,
        page: page ? Number(page) : 1,
        totalCount
      }))
    } else {
      dispatch(setElements([]))
      dispatch(setPagination(null))
    }

    isAdditionalLoading ? dispatch(setAdditionalLoading(false)) : dispatch(setLoading(false))
  } catch (e) {
    dispatch(setElements([]))
    dispatch(setPagination(null))
    isAdditionalLoading ? dispatch(setAdditionalLoading(false)) : dispatch(setLoading(false))
  }
}
